<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleOpen" v-if="userId === 101">编辑</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.username }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nianji_txt }}
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.xueke_txt }}
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="角色" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.role_name }}
				  </template>
				</el-table-column> -->
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 选择-->
		<el-dialog :visible.sync="openDialog" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="listData"
			  ref="multipleTable"
			  @selection-change="handleSelectionChange"
			  style="width: 100%">
			  <el-table-column
			    type="selection"
			    width="55">
			  </el-table-column>
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  label="姓名"
			  align="center"
			  prop="username">
			  </el-table-column>
			  <el-table-column
			  label="编号"
			  align="center"
			  prop="code">
			  </el-table-column>
			  <el-table-column
			  label="年级"
			  align="center"
			  prop="nianji_txt">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="center">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getTeachers"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
		        <span>{{ scope.row.xueke_txt }}</span>
			  </template>
			  </el-table-column>
			</el-table>
			<div class="w-100 flex-all pt-2 bx">
				<el-button type="primary" size="medium" @click="handerSubmit" style="width: 300px;">确定</el-button>
			</div>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="page.total"
				 auto-scroll
				 :page.sync="page.page"
				 :limit.sync="page.limit"
				 @pagination="getTeachers"
			   />
			  </div>
			</span>
		</el-dialog>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="名称" prop="username">
		      <el-input v-model="form.username" placeholder="请输入老师名称" type="text" clearable />
		    </el-form-item>
			<el-form-item label="编码" prop="code">
			  <el-input v-model="form.code" placeholder="请输入老师编码" type="text" clearable />
			</el-form-item>
			<el-form-item label="密码" prop="password">
			  <el-input v-model="form.password" placeholder="请输入登录密码" type="password" clearable />
			  <small class="text-danger font-s" v-if="form.id">为空表示不修改密码</small>
			</el-form-item>
		    <el-form-item label="电话" prop="mobile">
		      <el-input v-model="form.mobile" placeholder="请输入老师电话" type="text" clearable />
		    </el-form-item>
			<el-form-item label="年级" prop="nianji">
			  <el-select v-model="form.nianji" placeholder="请选择年级">
				<el-option :value="0" label="无"/>
			  	<el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
			  </el-select>
			</el-form-item>
			<el-form-item label="学科" prop="xueke">
			  <el-select v-model="form.xueke" placeholder="请选择学科">
			  	<el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
			  </el-select>
			</el-form-item>
			<el-form-item  label="性别">
			   <el-radio-group v-model="form.gender">
			   	<el-radio :label="1">男</el-radio>
			   	<el-radio :label="2">女</el-radio>
			   </el-radio-group>
			 </el-form-item>
			<el-form-item label="年龄" prop="age">
			  <el-input v-model="form.age" placeholder="请输入老师年龄" type="number" clearable />
			</el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">停用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'team_teacher',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					role_id:this.VueCookies.get('role_id'),
				},
				form:{
					username:'',
					password:'',
					mobile:'',
					code:'',
					gender:1,
					age:'',
					status:1,
					xueke:'',
					nianji:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入老师名称', trigger: 'blur' }
					],
					code: [
					  { required: true, message: '请输入老师编码', trigger: ['blur', 'change'] }
					],
				},
				nianji:[],
				xueke:[],
				teachers:[],
				list:[],
				page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openDialog:false,
				listData:[],
				team_id:[],
				select_ids:[],
                userId:this.VueCookies.get('userId'),
			}
		},
		mounted() {
			this.getNianji()
			this.getXueke()
		},
		methods:{
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/list`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = (res.data.length > 0) ? res.data : []
			    this.select_ids = res.team_id
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			getNianji(){
				this.axios.get('/manage/nianji/all').then(res=>{
					if(res.status){
						this.nianji = res.data
					}
				})
			},
			getXueke(){
				this.axios.get('/manage/xueke/all').then(res=>{
					if(res.status){
						this.xueke = res.data
					}
				})
			},
			reset() {
			  this.form = {
			    username:'',
				password:'',
			    mobile:'',
			    code:'',
			    gender:1,
			    age:'',
			    status:1,
			    xueke:'',
			    nianji:'',
			  }
			  this.resetForm('form')
			},
			getTeachers(){
				this.axios.get('/manage/teacher/list',{
					params:this.page
				}).then(res=>{
					if(res.status){
						this.listData = res.data
						this.page.total = res.total
						//回显选中
						this.$nextTick(()=>{
						    this.listData.forEach(row=>{
						       this.select_ids.forEach(selected =>{
						          if(selected == row.id){
						            this.$refs.multipleTable.toggleRowSelection(row,true);
						          }
						      })
						    })
						})
					}
				})
			},
			handleOpen(){
				 this.getTeachers()
				 this.$nextTick(()=>{
				   this.title = '教师列表'
				   this.openDialog = true
				 })
			},
            handleSel(){},
			handleSelectionChange(e){
				console.log(e)
				this.team_id = []
				if(e.length > 0){
					e.forEach((v)=>{
						this.team_id.push(v.id)
					})
				}
			},
			handerSubmit(){
				if(this.team_id.length == 0) return false
				this.axios.post('/manage/team_teacher/toEdit',{
					team_id:this.team_id
				}).then(res=>{
					if(res.status){
						this.openDialog = false
						this.$message.success('编辑成功')
						this.getList()
					}else{
						this.openDialog = false
						this.getList()
					}
				})
			},
		}
	}
</script>

<style>
.el-dialog__footer{
	padding: 0px 20px 20px!important;
}
</style>